<template>
  <div class="footer__wrapper">
    <footer class="footer">
      <div class="footer__top">
        <div class="footer__top-logo">
          <img
            src="../assets/logo-white.png"
            alt="logo"
          >
        </div>
        <div class="footer__top-links">
          <ul>
            <li>
              <router-link
                to="/#"
              >
                Home
              </router-link>
            </li>
            <li>
              <router-link
                to="/#about"
              >
                About Us
              </router-link>
            </li>
            <li>
              <router-link
                to="/#the-flight"
              >
                The Flight
              </router-link>
            </li>
            <li>
              <router-link
                to="/#faqs"
              >
                FAQs
              </router-link>
            </li>
            <!-- <li>
              <router-link
                to="/#meeting-location"
              >
                Meeting Location
              </router-link>
            </li> -->
            <li>
              <router-link
                to="/#contact"
              >
                Contact
              </router-link>
            </li>
          </ul>
        </div>
      </div>
      <div class="footer__bottom">
        <p class="footer__bottom-copyright">
          Copyright Moab Balloon Flights {{ new Date().getFullYear() }}
        </p>
        <div class="footer__bottom-social">
          <span>Follow us</span>
          <a :href="constants.FACEBOOK_URL"><i class="icon icon-facebook" /></a>
          <!--          <a href="#"><i class="icon icon-twitter" /></a>-->
          <!--          <a href="#"><i class="icon icon-linked-in" /></a>-->
          <a :href="constants.INSTAGRAM_URL"><i class="icon icon-instagram" /></a>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
import * as constants from '@/constants'

export default {
  name: 'Footer',
  data() {
    return {
      constants: constants
    }
  }
}
</script>

<style lang="scss" scoped>
.footer__wrapper {
  background: $secondary-color;

  .footer {
    &__top {
      @include max-width;
      display: flex;
      flex-flow: row wrap;
      justify-content: space-between;
      align-items: center;
      min-height: 100px;
      border-bottom: 1px solid $footer-line-break;
      &-logo {
        margin: 20px;
        img {
          width: 160px;
          height: auto;
        }
        @media (max-width: 823px) {
            margin: 20px auto;
        }
      }
      ul {
        display: flex;
        flex-flow: row wrap;
        justify-content: center;
        align-items: center;
        list-style-type: none;
        padding: 0;
        li {
          text-decoration: none;
          margin: auto 10px;
          @media screen and (max-width: 500px) {
            margin: 0;
          }
          a {
            display: inline-block;
            text-decoration: none;
            color: $footer-text-color;
            text-transform: uppercase;
            font-weight: 600;
            font-size: 0.9em;
            padding: 20px 5px;
            width: 100%;
            text-align: center;
            @media screen and (max-width: 500px) {
              padding: 0 5px;
            }
          }
        }
      }
      &-links {
        @media (max-width: 823px) {
            margin: 0 auto;
        }
      }
    }
    &__bottom {
      @include max-width;
      display: flex;
      flex-flow: row wrap;
      justify-content: space-between;
      align-items: center;
      min-height: 100px;
      border-bottom: 10px solid $primary-color;
      font-size: 0.8em;
      &-copyright {
        color: $footer-text-color;
        margin: 20px;
        @media (max-width: 823px) {
            margin: 20px auto;
        }
      }
      &-social {
        color: $primary-color;
        font-weight: 600;
        @media (max-width: 823px) {
            margin: 20px auto;
        }
        span {
          @media (max-width: 823px) {
            display: none;
          }
        }
      }
    }
  }
}
</style>
