<template>
  <div
    id="about"
    class="about"
  >
    <image-content
      image="about-flying.jpg"
    >
      <h2>Family Friendly Adventures</h2>
      <h1>About Us</h1>
      <p>We are a small, boutique hot air balloon company located in Moab Utah. We specialize in small groups, only 2-4 guests per flight. We feel this provides a unique, personalized experience not attainable in the larger baskets of 12-16+ people. Come join us today for an intimate family experience and be a part of a magical adventure while floating over Moab’s red rocks. </p>
      <p>Need a more intimate experience? We offer private and semi-private flights. Celebrating a birthday, anniversary or upcoming wedding? Let us help make it special! We fly year round (weather permitting). Do you have a unique adventure in mind? We are always excited for new possibilities! If you are exploring new marketing opportunities, we are happy to help! Schedule a flight with us today! We look forward to hearing from you!</p>
    </image-content>

    <!-- <WhyUs /> -->

    <div
      id="the-flight"
      class="about__flight"
    >
      <h2>Ultimate Bucket List Adventure</h2>
      <h1>The Flight</h1>

      <div class="about__flight-content">
        <div class="about__flight-left">
          <p>
            Your experience begins with a complimentary pick up at your hotel and a ride to the launch area in our private vehicle. Once we arrive at our launch spot, our crew will inflate the balloon while you watch and take photos. Once airborne, we will leisurely float over the desert landscape. You'll likely get to enjoy views of Arches National Park and the La Sal mountain range. The La Sal Mountains are truly a rarity; they are covered in snow until June, making for unique photo opportunities. Our red cliffs and white capped mountains are a geographical anomaly found in few other places in the world, these are a photographer’s delight! We often get the chance to observe desert wildlife such as pronghorns and rabbits.
          </p>
          <p>
            Did you know that the pronghorn is the fastest mammal in North America? Hopefully one will volunteer and make an appearance during your flight! You will spend the better part of an hour drifting through the canyons and over the mesas keeping an eye out for wildlife. Your flight will conclude as you touch softly down to mother earth.
          </p>
          <p>
            Following your flight, you will celebrate and commemorate it with a toast while we pack up the balloon. Once we’re finished loading up, you will then be returned to your hotel.
          </p>
          <p>
            <strong>Note suitable</strong> for pregnant women or children under 6 years of age.
          </p>
        </div>
        <div class="about__flight-right">
          <div class="about__flight-benefits">
            <p>
              <b>WHAT WE OFFER</b>
            </p>
            <ul class="about__list">
              <li>
                <i class="icon icon-car" />
                <p class="about__list-title">
                  Pick up and drop off at your hotel
                  <br>
                  <label class="about__list-subtitle">
                    In town guests only. We will pick you up from your hotel and transport you to the launch site in a private vehicle. Expect to be gone around 4-4.5hrs
                  </label>
                </p>
              </li>
              <li>
                <i class="icon icon-safety" />
                <p class="about__list-title">
                  Full safety briefing
                  <br>
                  <label class="about__list-subtitle">
                    Safety is our first priority. You'll receive instruction from a fully insured, FAA commercial pilot.
                  </label>
                </p>
              </li>
              <li>
                <i class="icon icon-balloon" />
                <p class="about__list-title">
                  Adventure flight
                  <br>
                  <label class="about__list-subtitle">
                    Once airborne, we're at the mercy of the winds. Flights typically range from 40-90 minutes.
                  </label>
                </p>
              </li>
              <li>
                <i class="icon icon-booze" />
                <p class="about__list-title">
                  Celebretory toast
                  <br>
                  <label class="about__list-subtitle">
                    What better way to end a morning adventure
                  </label>
                </p>
              </li>
            </ul>
            <br>
            <p>
              <b>SEE MOAB FORM ABOVE...</b>
            </p>
            <ul class="about__highlight">
              <li>Admire the vast array of red rocks and cliffs at sunrise while you float by</li>
              <li>Enjoy fantastic views of desert flora, fauna and possible wildlife sightings (on occasion our flights even take us over dinosaur tracks!)</li>
              <li>Potential to view Arches National Park and the La Sal mountain range from the sky!</li>
              <li>Learn a little more about the canyons surrounding Moab</li>
            </ul>
          </div>
        </div>
      </div>

      <div class="prices">
        <div class="price-content">
          <div class="price">
            <p class="price-amount">
              $295
            </p>
            <p class="price-target">
              Adults 18+
            </p>
          </div>
          <div class="price">
            <p class="price-amount">
              $275
            </p>
            <p class="price-target">
              Children 6-17
            </p>
          </div>
        </div>

        <div class="book">
          <router-link to="/book">
            <button class="btn btn-primary book-now">
              Book Now
            </button>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ImageContent from './ImageContent'
//import WhyUs from './WhyUs'

export default {
  name: 'About',
  components: {
    ImageContent
  }
}
</script>

<style lang="scss" scoped>
.about {

  h1, h2 {
    @include breakpoint(phone-screen) {
      text-align: center;
    }
  }

  strong { font-weight: bold; }
  &__flight{
    background: #fff;

    @include breakpoint(phone-screen) {
      padding: 30px 0px 40px 0px;
    }
    @include breakpoint(tablet-screen) {
      padding: 30px 0px 45px;
    }
    @include breakpoint(desktop-screen) {
      padding: 50px 0px 40px;
    }
    @include breakpoint(large-desktop-screen) {
      padding: 80px 120px 60px;
    }
  }

  &__flight-highlight{
    padding: 0px 300px;

    @include breakpoint(phone-screen) {
      padding: 0px 15px;
    }

    ul {
      text-align: center;

      li {
        line-height: 2em;
      }
    }
  }

  &__flight-content{
    @include container('wide');
    display: grid;
    grid-template-columns: 1.1fr 0.9fr;
    grid-column-gap: 65px;
    margin-top: 70px;

    @media screen and (min-width: 768px) and (max-width: 1100px) {
      grid-column-gap: 40px;
    }

    @media screen and (max-width: 900px) {
      grid-template-columns: 1fr;
      margin-top: 0;
    }

    .about__flight-left{
      text-align: left;

      p {
        padding: 0;
        margin: 0;
        margin-bottom: 30px;
        font-size: 17px;
      }

      @include breakpoint(phone-screen) {
        padding: 10px 0;
      }
    }

    .about__flight-right{
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;
      text-align: left;

      .about__flight-benefits{
        width: 100%;

        p {
          margin-top: 0;
        }
      }

      b {
        font-weight: bold;
      }

      @include breakpoint(phone-screen) {
        padding: 10px 0;
      }

      .about__list {
        list-style: none;
        padding-inline-start: 0px;
        margin-top: 0;
        width: 100%;
        li {
          border-bottom:1px solid rgba(155,155,155,0.5);
          padding: 15px 0px 5px;
          display: grid;
          grid-template-columns: 80px 100%;

          .icon {
            width: 60px;
            height: 50px;
            margin: 0;

            &.icon-car {
              background: url('./../assets/icons/car@1x.svg') no-repeat;
              background-position-x: center;
            }
            &.icon-safety {
              background: url('./../assets/icons/safety@1x.svg') no-repeat;
              background-position-x: center;
            }
            &.icon-balloon {
              background: url('./../assets/icons/balloon@1x.svg') no-repeat;
              background-position-x: center;
            }
            &.icon-booze {
              background: url('./../assets/icons/booze@1x.svg') no-repeat;
              background-position-x: center;
            }
          }

          .about__list-title {
            font-size: 16px;
            font-weight: 600;
            line-height: 1.4em;
            margin: 0;
            max-width: 80%;
            padding-top: 0;
          }
          .about__list-subtitle{
            font-size: 14px;
            font-weight: 300;
          }
        }
      }

      .about__highlight {
        list-style-type: none;
        padding-left: 20px;

        li {
          line-height: 24px;
          padding-bottom: 10px;
          margin-left: 6px;

          &:before {
            content: "\2022";
            color: #E85026;
            font-weight: bold;
            display: inline-block;
            width: 1em;
            margin-left: -1em;
            margin-top: 0px;
            font-size: 24px;
            position: absolute;
          }
        }
      }
    }

    @include breakpoint(phone-screen) {
      grid-template-columns: 1fr;
    }
  }

  .prices {
    @include container('wide');
    border-top: 1px solid rgba(155, 155, 155, 0.5);
    padding-top: 30px;
    display: grid;
    grid-template-columns: 1.1fr 0.9fr;

    @include breakpoint(phone-screen) {
      grid-template-columns: 1fr;
    }

    .price-content {
      display: flex;

      @include breakpoint(phone-screen) {
        justify-content: center;
      }

      .price {
        width: fit-content;
        padding-right: 50px;

        @include breakpoint(phone-screen) {
          width: 50%;
          padding-right: 0;
        }

        .price-amount{
          font-size: 3em;
          font-weight: 600;
          line-height: 1em;
          margin:0;

          @include breakpoint(phone-screen) {
            font-size: 2em;
          }
        }

        .price-target{
          line-height: 1em;
          margin: 0;
          text-align: center;
          color: $primary-color;
        }
      }
    }

    .book-now{
      float: right;
      width: 250px;
      height: 60px;

      @include breakpoint(phone-screen) {
        float: none;
        width: 100%;
        margin-top: 30px;
      }

    }
  }
}
</style>
