<template>
  <div
    v-if="posts"
    class="instagram"
  >
    <a :href="constants.INSTAGRAM_URL">
      <h2>Follow Us On Instagram</h2>
      <h1>{{ constants.INSTAGRAM_HANDLE }}</h1>
    </a>
    <div class="instagram__posts">
      <div
        v-for="(post,index) in posts"
        :key="index"
        class="instagram__posts-post"
      >
        <div v-if="post.url">
          <a
            :href="post.url"
            class="instagram__posts-post"
          >
            <img
              :src="post.image"
              alt="instagram post"
            >
          </a>
        </div>
        <div v-else>
          <img
            :src="post.image"
            alt="instagram post"
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as constants from '@/constants'

export default {
  name: 'Instagram',
  data() {
    return {
      constants: constants,
      posts: [
        {
          "image": require('@/assets/images/instagram/instagram-1.png')
        },
        {
          "image": require('@/assets/images/instagram/instagram-4.jpg')
        },
        {
          "image": require('@/assets/images/instagram/instagram-3.jpg')
        },
        {
          "image": require('@/assets/images/instagram/instagram-2.jpg')
        }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
.instagram {
  background: white;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 35px;

  @include breakpoint(phone-screen) {
    padding-top: 20px;
    padding-bottom: 45px;
  }
  @include breakpoint(tablet-screen) {
    padding-top: 40px;
    padding-bottom: 65px;
  }
  @include breakpoint(desktop-screen) {
    padding-top: 70px;
    padding-bottom: 110px;
  }
  a {
    text-decoration: none;
    color: currentColor;
  }
  &__posts {
    @include max-width;
    display: grid;
    grid-gap: 30px;
    grid-template-columns: repeat(auto-fit, 255px);
    justify-content: center;
    justify-content: center;
    @media (max-width: 1150px) {
      grid-template-columns: 255px 255px;
      grid-auto-rows: 1fr;
    }
    @media (max-width: 610px) {
      grid-template-columns: 1fr 1fr;
      grid-auto-rows: 1fr;
      grid-gap: 5px;
      justify-items: center;
      align-items: center;
    }
    &-post{
      align-self:stretch;
      img {
        max-width: 255px;
        width: 100%;
      }
    }
  }
}
</style>
