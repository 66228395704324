<template>
  <div id="home">
    <Header />
    <About />
    <!-- <fluid-img
      desktopImage="../assets/images/moab-1.jpg"
      mobileImage="../assets/images/moab-1-mobile.jpg"
    /> -->

    <picture class="fluid-img">
      <!-- FIXME: image source not displaying correctly -->
      <source
        srcset="../assets/images/moab-wide.jpg"
        media="(min-width: 767px)"
        class="fluid-img"
      >
      <img
        src="../assets/images/moab-1-mobile.jpg"
        class="fluid-img"
      >
    </picture>

    <!-- <Reviews /> -->
    <Faqs />
    <Book />
    <!-- <MeetingLocation /> -->
    <Contact />
    <Instagram />
  </div>
</template>

<script>
import About from '../components/About.vue'
import Book from '../components/Book.vue'
import Contact from '../components/Contact.vue'
import Faqs from '../components/Faqs.vue'
import Header from '../components/Header.vue'
import Instagram from '../components/Instagram.vue'
//import Reviews from '../components/Reviews.vue'
//import MeetingLocation from '../components/MeetingLocation.vue'
//import FluidImg from '../components/FluidImg'

export default {
  name: 'App',
  components: {
    About,
    Book,
    Contact,
    Faqs,
    Header,
    Instagram,
  }
}
</script>

<style lang="scss">
.fluid-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: top;
  max-height: 600px;

  @media screen and (min-width: 1200px) {
    max-height: 850px;
  }

  img {
    display: block;
  }
}
</style>
