<template>
  <div class="book">
    <h2>
      Best Paroamic Views of Moab
    </h2>
    <h1 class="book__title">
      Book Your Flight
    </h1>

    <div class="book__content">
      <div>
        <p class="book__content-description">
          There is no better way to experience the beauty of Moab, Utah and the canyons surrounding it than from the sky. Enjoy airy views of Arches National Park, the La Sal mountain range and our very own red sandy cliffs. This is an opportunity for you to make memories that will last a lifetime.
        </p>

        <p class="book__content-cancelation">
          “Once you have flown you will walk the earth with your eyes turned skyward, for there you have been, and there you are long to return.”  -Leonardo da Vinci
        </p>
      </div>
    </div>
    <router-link to="/book">
      <button class="btn btn-primary schedule-now">
        Book Now
      </button>
    </router-link>
  </div>
</template>

<script>
export default {
  name: 'Book'
}
</script>

<style lang="scss" scoped>
  .book {
    @include container('short');
    padding: 40px 0;

    @include breakpoint(phone-screen) {
      padding: 25px 0;
    }

    &__content {
      display: flex;
      justify-content: center;
      align-items: center;

      div {
        max-width: 968px;

        .book__content-description {
          text-align: center;
        }

        .book__content-cancelation {
          font-weight: bold;
          max-width: 75%;
          margin: auto;
        }
      }
    }

    .schedule-now {
      padding: 15px;
      margin-top: 35px;
      margin-bottom: 30px;
      width: 250px;
    }
  }
</style>
