<template>
  <div>
    <div
      class="desktop-nav"
      :class="[{ 'sticky': !atTopOfPage}, position]"
    >
      <!-- FIXME: Finish adding mobile images & mobile video (and compress) -->
      <div class="desktop-nav__logo">
        <router-link
          to="/"
        >
          <img src="../assets/images/logo.svg">
        </router-link>
      </div>

      <div class="desktop-nav__links">
        <ul>
          <li>
            <router-link
              to="/"
            >
              Home
            </router-link>
          </li>
          <li>
            <router-link
              to="/#about"
            >
              About Us
            </router-link>
          </li>
          <li>
            <router-link
              to="/#the-flight"
            >
              The Flight
            </router-link>
          </li>
          <li>
            <router-link
              to="/#faqs"
            >
              FAQs
            </router-link>
          </li>
          <!-- <li>
            <router-link
              to="/#meeting-location"
            >
              Meeting Location
            </router-link>
          </li> -->
          <li>
            <router-link
              to="/#contact"
            >
              Contact
            </router-link>
          </li>
          <li>
            <a
              href="tel:855-MOABSKY"
              class="phone"
            >855-MOABSKY</a>
          </li>
          <li>
            <router-link to="/book">
              <button class="btn btn-primary btn--menu">
                Book Now
              </button>
            </router-link>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import $ from 'jquery';
export default {
  name: 'MenuDesktop',
  props: {
    position: {
      type: String,
      default: ""
    }
  },
  data: function () {
    return {
      atTopOfPage: true
    }
  },
  created () {
    window.addEventListener('scroll', this.handleSCroll);

    // FIXME: scroll to
    // if(window.location.hash) {
    //   var hash = window.location.hash
    //   console.log("Hash: ", hash)
    //   $('html, body').animate({
    //     scrollTop: $(hash).offset().top
    //   }, 1500, 'swing')
    // }
  },
  destroyed () {
    window.removeEventListener('scroll', this.handleSCroll);
  },
  methods: {
    scrollToTop() {
      window.scrollTo(0,0);
    },
    handleSCroll (event) {
      let doc = document.documentElement;
      let top = (window.pageYOffset || doc.scrollTop)  - (doc.clientTop || 0)

      // FIXME: Add offset
      this.atTopOfPage = (top === 0) ? true : false
    }
  }
}
</script>

<style lang="scss" scoped>
.desktop-nav {
  width: 100%;
  z-index: 100;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  background-color: $secondary-color;

  &.absolute {
    position: absolute;
    top: 0;
    background: rgb(000, 000, 000); /* Fallback for older browsers without RGBA-support */
    background: rgba(000, 000, 000, 0.3);
  }

  &.sticky {
    position: fixed;
    top: 0;
    width: 100%;
    background: $secondary-color;
  }

  &__logo {
    margin-left: 20px;

    img {
      max-height: 47px;
    }
  }

  &__links {
    margin-right: 20px;

    ul {
      display: flex;
      align-items: center;
      li {
        display: inline-block;

        a {
          color: white;
          font-weight: bold;
          text-transform: uppercase;
          text-decoration: none;
          font-size: 14px;
          padding: 12px 12px;

          @media screen and (max-width: 1160px) {
            padding: 5px 13px;
          }

          &.router-link-exact-active {
            color: $primary-color;
          }

          &:hover {
            color: $primary-color;
          }

          &.phone {
            padding: 0 0 0 20px;
            font-size: 17px;
          }
        }
      }
    }
  }
}
</style>
