import Vue from 'vue'
import VueRouter from 'vue-router'
import VueGtag from 'vue-gtag';

import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    mode: 'history',
    path: '/',
    name: 'home',
    component: Home,
  },
  {
    path: '/book',
    name: 'booking',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Booking.vue')
  },
  {
    path: '*',
    component: () => import('../components/PageNotFound.vue'),
    meta: {
      title: '404 - Page Not Found',
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      if (to.hash) {
        return window.scrollTo({
          top: document.querySelector(to.hash).offsetTop - 50,
          behavior: 'smooth'
        })
      } else {
        return { x: 0, y: 0 }
      }
    } else if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 }
    }
  },
  routes,
})

Vue.use(VueGtag, {
  config: { id: "GTM-W5V6PR6" },
  appName: 'Moab Balloon Flights',
  pageTrackerScreenviewEnabled: true
}, router);

export default router
