<template>
  <div>
    <MenuDesktop
      :position="position"
      class="menu-desktop"
    />
    <MenuMobile
      class="menu-mobile"
      :position="position"
    />
  </div>
</template>

<script>
import MenuDesktop from './MenuDesktop'
import MenuMobile from './MenuMobile'

export default {
  name: 'Menu',
  components: {
    MenuDesktop,
    MenuMobile
  },
  props: {
    position: {
      type: String,
      default: ""
    }
  }
}
</script>

<style lang="scss" scoped>
$menu-breakpoint: 1100px;

@media screen and (min-width: $menu-breakpoint + 1px) {
  .menu-desktop { display: block; }
  .menu-mobile { display: none; }
}

@media screen and (max-width: $menu-breakpoint) {
  .menu-desktop { display: none; }
  .menu-mobile { display: block; }
}
</style>
