<template>
  <div id="app">
    <vue-progress-bar />
    <Menu
      :position="menuAttributes ? 'absolute' : ''"
    />
    <router-view />
    <Footer />
    <back-to-top
      bottom="30px"
      right="30px"
    >
      <button class="btn-to-top">
        <i class="fa fa-chevron-up" />
      </button>
    </back-to-top>
  </div>
</template>

<script>
import Menu from './components/Menu.vue'
import Footer from './components/Footer.vue'

export default {
  name: 'App',
  components: {
    Menu,
    Footer
  },
  computed: {
    menuAttributes() {
      return this.$route.path == "/" ? true : false
    }
  },
  mounted () {
    //  [App.vue specific] When App.vue is finish loading finish the progress bar
    this.$Progress.finish()

    if(window.location.hash) {
      var hash = window.location.hash
      console.log("Hash 2 ", hash)
      // $('html, body').animate({
      //   scrollTop: $(hash).offset().top
      // }, 1500, 'swing');
    }

  },
  created () {
    //  [App.vue specific] When App.vue is first loaded start the progress bar
    this.$Progress.start()
    //  hook the progress bar to start before we move router-view
    this.$router.beforeEach((to, from, next) => {
      //  does the page we want to go to have a meta.progress object
      if (to.meta.progress !== undefined) {
        let meta = to.meta.progress
        // parse meta tags
        this.$Progress.parseMeta(meta)
      }
      //  start the progress bar
      this.$Progress.start()
      //  continue to next page
      next()
    })
    //  hook the progress bar to finish after we've finished moving router-view
    this.$router.afterEach((to, from) => {
      //  finish the progress bar
      this.$Progress.finish()
    })
  }
}
</script>

<style lang="scss">
.btn-to-top {
  width: 45px;
  height: 45px;
  padding: 10px 14px;
  border-radius: 50%;
  font-size: 17px;
  line-height: 15px;
  color: black;
  i {
    vertical-align: bottom;
  }
  @media (min-width: 1024px) {
    display: none;
  }
}
.vue-back-to-top {
  z-index: 99!important;
}
</style>
