<template>
  <div class="header">
    <!-- :autoplay="true" -->
    <carousel
      :items="1"
      :nav="false"
      :loop="true"
      :autoplay-timeout="8000"
      :autoplay-speed="1000"
      :lazy-load="true"
      dots-container=".header__slider-dots"
    >
      <div>
        <picture class="header__img">
          <source
            srcset="../assets/images/slider/bg1.jpg"
            media="(min-width: 767px)"
            class="header__img"
          >
          <img
            src="../assets/images/slider/bg1-mobile.jpg"
            class="header__img"
          >
        </picture>

        <!-- <div class="text-overlay">
          <p>Adventure Awaits</p>
          <div class="book">
            <button class="btn btn-primary book-now">
              Book Now
            </button>
          </div>
        </div> -->
      </div>
      <div>
        <picture class="header__img">
          <source
            srcset="../assets/images/slider/bg2.jpg"
            media="(min-width: 767px)"
            class="header__img"
          >
          <img
            src="../assets/images/slider/bg2-mobile.jpg"
            class="header__img"
          >
        </picture>
      </div>
      <!-- <div>
        <video
          playsinline
          autoplay
          muted
          loop
          class="video-desktop"
        >
          <source
            src="../assets/videos/intro-v2.mp4"
            type="video/mp4"
          >
        </video>

        <video
          playsinline
          autoplay
          muted
          loop
          class="video-mobile"
        >
          <source
            src="../assets/videos/intro-v2-mobile.mp4"
            type="video/mp4"
          >
        </video>
      </div> -->
      <div>
        <picture class="header__img">
          <source
            srcset="../assets/images/slider/bg8.jpg"
            media="(min-width: 767px)"
            class="header__img"
          >
          <img
            src="../assets/images/slider/bg8-mobile.jpg"
            class="header__img"
          >
        </picture>
      </div>
      <div>
        <picture class="header__img">
          <source
            srcset="../assets/images/slider/bg6.jpg"
            media="(min-width: 767px)"
            class="header__img"
          >
          <img
            src="../assets/images/slider/bg6-mobile.jpg"
            class="header__img"
          >
        </picture>
      </div>
      <!-- <div>
        <picture class="header__img">
          <source
            srcset="../assets/images/slider/bg7.jpg"
            media="(min-width: 767px)"
            class="header__img"
          >
          <img
            src="../assets/images/slider/bg7-mobile.jpg"
            class="header__img"
          >
        </picture>
      </div> -->
      <div>
        <picture class="header__img">
          <source
            srcset="../assets/images/slider/bg3.jpg"
            media="(min-width: 767px)"
            class="header__img"
          >
          <img
            src="../assets/images/slider/bg3-mobile.jpg"
            class="header__img"
          >
        </picture>
      </div>
    </carousel>
    <div class="header__slider-dots" />
  </div>
</template>

<script>
import carousel from 'vue-owl-carousel'

export default {
  name: 'Header',
  components: {
    carousel
  }
}
</script>

<style lang="scss" scoped>
.header {
  position: relative;

  &__img {
    max-width: 100%;
  }

  video {
    max-width: 100%;
    width: 100%;
  }
}
</style>

<style lang="scss">
@include breakpoint(phone-screen) {
  .video-desktop { display: none; }
  .video-mobile { display: block; }
}
@media screen and (min-width: 768px) {
  .video-desktop { display: block; }
  .video-mobile { display: none; }
}

.header {

  &__img {
    position: relative;
  }

  .text-overlay {
    position: absolute;
    top: 40%;
    left: 0;
    right: 0;

    color: white;
    font-size: 45px;
    font-weight: bold;
    margin: 0;
  }
  .owl-carousel {
    .owl-item {
      @include breakpoint(phone-screen) {
        height: 80%;
      }
    }
  }
}

.header__slider-dots {
  @include owl-slider-dots();

  position: absolute;
  bottom: 5px;
  width: 100%;
  margin: 0 auto 15px;

  .owl-dot {
    background: none;
    border-radius: 30px;

    span {
      width: 9px;
      height: 9px;
      background: transparent;
      border: 2px solid white;
    }

    &.active {
      span {
        background: white;
      }
    }
  }
}
</style>
