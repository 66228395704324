import Vue from 'vue'
import App from './App.vue'
import router from './router'

import Axios from 'axios'
import VueProgressBar from 'vue-progressbar'
import BackToTop from 'vue-backtotop'

import jQuery from "jquery";
const $ = jQuery;

//import './utils/jquery.smooth-scroll-pageload.js'
import './assets/css/variables.scss'
import './assets/css/index.scss'

//window.$ = window.jQuery = require('jquery');

Vue.prototype.axios = Axios;
Vue.config.productionTip = false

Vue.use(BackToTop)

const vueProgressBarOptions = {
  color: '#E85026',
  failedColor: '#874b4b',
  thickness: '5px',
  transition: {
    speed: '0.2s',
    opacity: '.7',
    termination: 300
  },
  autoRevert: true,
  location: 'top',
  inverse: false
}
Vue.use(VueProgressBar, vueProgressBarOptions)

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
