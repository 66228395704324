<template>
  <div>
    <div
      class="mobile-nav"
      :class="[{ 'sticky': !atTopOfPage}, { 'absolute': position}]"
    >
      <div class="mobile-nav__logo">
        <router-link
          to="/"
          @click.native="toggleMenu(true)"
        >
          <img src="../assets/images/logo.svg">
        </router-link>
      </div>
      <router-link
        to="/book"
        class="btn btn-primary book-now-top"
        @click.native="toggleMenu(true)"
      >
        Book Now
      </router-link>
      <a
        class="mobile-nav__button"
        :class="{'active': isActive}"
        @click="toggleMenu"
      >
        <span class="lines" />
      </a>
      <nav
        class="mobile-nav__main-menu"
        :class="{'active': isActive}"
      >
        <div class="mobile-nav__main-menu-wrapper">
          <ul>
            <li>
              <router-link
                to="/"
                @click.native="toggleMenu"
              >
                Home
              </router-link>
            </li>
            <li>
              <router-link
                to="/#about"
                @click.native="toggleMenu"
              >
                About Us
              </router-link>
            </li>
            <li>
              <router-link
                to="/#the-flight"
                @click.native="toggleMenu"
              >
                Flights
              </router-link>
            </li>
            <li>
              <router-link
                to="/#faqs"
                @click.native="toggleMenu"
              >
                FAQ
              </router-link>
            </li>
            <!-- <li>
              <router-link
                to="/#meeting-location"
                @click.native="toggleMenu"
              >
                Meeting Location
              </router-link>
            </li> -->
            <li>
              <router-link
                to="/#contact"
                @click.native="toggleMenu"
              >
                Contact
              </router-link>
            </li>
            <li>
              <router-link
                to="/book"
                class="btn btn-primary book-now"
                @click.native="toggleMenu(true)"
              >
                Book Now
              </router-link>
            </li>
            <li>
              <div class="contact">
                <span class="subtitle">Call for more infomation</span>
                <a
                  href="tel:855-MOABSKY"
                  class="title"
                >
                  855-MOABSKY
                  <br>
                  (622-2759)
                </a>
              </div>
            </li>
            <li>
              <a :href="constants.FACEBOOK_URL"><i class="icon icon-facebook" /></a>
              <!--  <a href="#"><i class="icon icon-twitter" /></a>-->
              <!--  <a href="#"><i class="icon icon-linked-in" /></a>-->
              <a :href="constants.INSTAGRAM_URL"><i class="icon icon-instagram" /></a>
            </li>
          </ul>
        </div>
      </nav>
    </div>
  </div>
</template>

<script>
import * as constants from '@/constants'

export default {
  name: 'MenuMobile',
  props: {
    position: {
      type: String,
      default: ""
    }
  },
  data: function () {
    return {
      atTopOfPage: true,
      isActive: false,
      constants: constants
    }
  },
  created () {
    window.addEventListener('scroll', this.handleSCroll)
  },
  destroyed () {
    window.removeEventListener('scroll', this.handleSCroll)
  },
  methods: {
    scrollToTop() {
      window.scrollTo(0,0);
    },
    handleSCroll (event) {
      let doc = document.documentElement;
      let top = (window.pageYOffset || doc.scrollTop)  - (doc.clientTop || 0)

      this.atTopOfPage = (top === 0) ? true : false
    },
    toggleMenu (close = false) {
      if (close == true) {
        this.isActive = false;
      } else {
        this.isActive = !this.isActive;
      }
      if (this.isActive == true) {
        document.documentElement.style.overflow = 'hidden';
      } else {
        document.documentElement.style.overflow = 'auto';
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.mobile-nav {
  // position: absolute;
  width: 100%;
  padding: 10px 0;
  z-index: 100;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  background-color: rgba(61,13,19,0.6);

  &.absolute {
    position: absolute;
    top: 0;
    background: rgb(000, 000, 000); /* Fallback for older browsers without RGBA-support */
    background: rgba(000, 000, 000, 0.3);
  }

  &.sticky {
    position: fixed;
    top: 0;
    width: 100%;
    background-color: $secondary-color;
  }

  .book-now {
    text-transform: none;
    width: 155px;
    padding: 15px;
    margin-top: 15px;
  }

  .book-now-top {
    font-size: 0.7em;
    text-decoration: none;
    position: absolute;
    right: 66px;
    padding: 5px;
    z-index: 101;
  }

  .icon {
    margin: 20px 25px 0px 25px;
  }

  .sub-number {
    margin-left: 35px;
  }

  &__logo {
    margin-left: 20px;
    z-index: 101;
    img {
      max-height: 35px;
    }
  }

  &__button {
    height: 30px;
	  width: 30px;
    position: absolute;
    right: 20px;
    z-index: 101;
    cursor: pointer;

    span {
      background-color: $primary-color;
      border-radius: 1px;
      height: 3px;
      width: 100%;
      position: absolute;
      left: 50%;
      top: 50%;
      margin: -1px 0 0 -15px;
      transition: height 100ms;
      &:after, &:before {
        content: '';
        background-color: $primary-color;
        border-radius: 1px;
        height: 3px;
        width: 100%;
        position: absolute;
        left: 50%;
        top: 8px;
        margin-left: -15px;
        transition: all 200ms;
      }
      &:after {
        top: -8px;
      }
      &:before {
        bottom: -8px;
      }
    }

    &.active {
      span {
        height: 0;
        &:after, &:before {
          background-color: $primary-color;
          top: 1px;
        }
        &:after {
          transform: rotate(45deg);
        }
        &:before {
          transform: rotate(-45deg);
        }
      }
    }
  }

  &__main-menu {
    background-color: $secondary-color;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 100;
    height: 100%;
    width: 100%;
    display: none;
    text-align: center;
    overflow: scroll;

    &.active {
      display: block;
    }

    &-wrapper {
      display: table;
      width: 100%;
      margin: 110px 0px 50px;
    }

    ul {
      display: table-cell;
      vertical-align: top;
      padding-inline-start: 0px;
    }
    li {
      list-style-type: none;
      padding: 12px 0;
      &:last-child {
        transform: none;
      }
    }
    a {
      color: white;
      display: inline-block;
      font-size: 1em;
      text-decoration: none;
      text-transform: uppercase;
      font-weight: 600;
    }
    .contact {
      margin: 0 auto;
      margin-top: 25px;
      border-top: 1px solid $footer-line-break;
      border-bottom: 1px solid $footer-line-break;
      .subtitle {
        display: block;
        color: #E85026;
        margin: 35px 0px 15px 0px;
        font-size: 1em;
        font-weight: 600;
      }
      .title {
        display: block;
        color: white;
        font-size: 1.4em;
        font-weight: 600;
        margin-bottom: 35px;
        line-height: 30px;
      }
    }
  }
}
</style>
