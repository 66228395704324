<template>
  <div class="image-content">
    <div
      class="image-content__intro"
    >
      <div class="image-content__image">
        <img
          :src="require(`../assets/images/${image}`)"
          alt=""
        >
      </div>
      <div class="image-content__right-content">
        <slot />
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'ImageContent',
  props: {
    image: {
      type: String,
      default: ""
    }
  },
}
</script>

<style lang="scss" scoped>
$breakpoint: 1000px;

.image-content {
  &__intro {
    display: grid;
    grid-template-columns: 1fr 1fr;
    background: #F9FAFB;

    @media screen and (max-width: $breakpoint) {
      grid-template-columns: 1fr;
      .image-content__right-content {
        order: 1;
      }
      .image-content__image {
        order: 2;
      }
    }
    .image-content__image {
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;

        @media screen and (max-width: $breakpoint) {
          max-height: 600px;
        }
      }
    }

    .image-content__right-content {
      padding: 60px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      text-align: left;

      @include breakpoint(phone-screen) {
        padding: 10px 0;
        @include container();
      }
    }
  }
}
</style>
