<template>
  <div
    id="faqs"
    class="faq"
  >
    <h1 class="faq__subtitle">
      Frequently Asked Questions
    </h1>
    <div class="faq__wrapper">
      <div class="faq-accordion">
        <div
          v-for="(item, i) in faqs"
          :key="item.id"
          class="faq-item"
        >
          <button
            :class="{ active: item.show }"
            class="accordion"
            @click="toggleItem(item, i)"
          >
            {{ item.title }}
          </button>
          <collapse-transition>
            <div
              v-show="item.show"
              class="panel"
            >
              <!-- eslint-disable-next-line vue/no-v-html -->
              <p v-html="item.value" />
            </div>
          </collapse-transition>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { CollapseTransition } from 'vue2-transitions'

export default {
  name: 'Faqs',
  components: {
    CollapseTransition
  },
  data() {
    return {
      faqs: [
        {
          id: 0,
          show: true,
          title: 'Is this your first Balloon flight?',
          value: 'Whether you are thinking about going up or have just booked your first flight, here are a few balloon FAQs to explain what to expect before, during and after your flight. If you have any additional questions or concerns, please give us a call. Our goal here at Moab Balloon Flights is to share the gift of flight by providing each guest with a unique, personal and enjoyable experience.'
        },
        {
          id: 1,
          show: false,
          title: 'Where do we fly?',
          value: 'We fly over canyons and desert areas around Moab. We typically take off north of town. Daily flight locations will vary due to wind conditions.',
        },
        {
          id: 2,
          show: false,
          title: 'Is hot air ballooning safe?',
          value: 'Hot air ballooning is one of the safest forms of aviation. All of our pilots hold FAA issued commercial licenses and have perfect safety records (verifiable on ntsb.org). All of our equipment is maintained to FAA required standards and inspected every 100 hrs.',
        },
        {
          id: 3,
          show: false,
          title: 'How far does a balloon travel?',
          value: 'Flights cover between 2-8 miles, depending upon wind speed and direction.',
        },
        {
          id: 4,
          show: false,
          title: 'How long will my adventure be?',
          value: 'Please allow 4 - 4 ½ hrs from pick up at your hotel. A with all airsports, we are a little at the mercy of the winds. We aim to fly 45 min to an hour on each flight. Pilots must take into account various weather variables on every flight, in order to make a safe flight and landing. One of the larger variables is wind speed and direction. Due to variances in wind speed and direction, flights ranging from 35 min to 1hr and 45 min are sometimes possible.',
        },
        {
          id: 5,
          show: false,
          title: 'What should I wear for my balloon ride?',
          value: 'Comfortable, closed-toe, flat shoes like boots or sneakers (please, no heels or flip flops), warm socks, casual outdoor active wear, warm clothes, pants, layers, and a jacket (the desert can be a bit chilly in the mornings). We also suggest gloves, a hat, and sunglasses (even though we start out in the dark, the sun will soon be rising).',
        },
        {
          id: 6,
          show: false,
          title: 'What can I bring?',
          value: 'Here are a few suggested but not required items to bring: a CAMERA! sunscreen, hat, selfie stick, sunglasses, a jacket, gloves. Due to space constrictions in the basket, things such as backpacks, large handbags, duffel bags, purses and large camera bags will not be permitted in the basket. Any items not permitted in the basket can stay in our chase vehicle while you fly. You will be able to access these items once our chase vehicle meets us at our landing location.',
        },
        {
          id: 7,
          show: false,
          title: 'What time do we meet?',
          value: 'Because of climatic conditions here in the desert, we primarily only fly in the early mornings (on occasion, we will fly in the evenings), meeting just before sunrise. Since our launch times vary during the season with the rising sun, we ask our guests to call ahead the day prior for an exact meeting time and location.'
        },
        {
          id: 8,
          show: false,
          title: 'What will my adventure look like?',
          value: 'I hope you are ready for a truly, memorable desert experience! Plan to wake up bright and early, but don’t let this deter you! You get to view the desert from the best place - THE SKY! You get to take in the sights and sounds of the land waking up around you and the sun as she slowly turns your surroundings pink. The morning of your flight we will pick you up in a private vehicle from your hotel (or anywhere within 5 miles of downtown Moab) and take you to our launch location *. This is a great time for photos with the sun rising over the red rocks and the brightly colored balloon filling with air. We will then launch and have our flight over the desert and canyons with the La Sal mountains in the distance. We encourage our guests to bring cameras to capture this truly scenic and stunning flight. Balloons are creatures of the wind, so we don\'t always know exactly where we will land, this is why we have a chase vehicle with whom we are in radio contact with that will follow us to where we touch down. Once we touch down, guests will enjoy a toast to commemorate their flight before being returned to their hotels.\n' +
            '*Sorry only in town pick up available'
        },
        {
          id: 9,
          show: false,
          title: 'How many people can go flying?',
          value: 'We specialize in small groups. Our current balloon accommodates 3 passengers.'
        },
        {
          id: 10,
          show: false,
          title: 'What about bad weather?',
          value: 'High winds, rain, and snow can prevent us from flying. We ask all our guests to contact us in the early afternoon prior to the day of their scheduled flight to verify weather conditions and pickup locations. Guests will not be charged for flight cancellations by Moab Balloon Flights due to weather or safety concerns.'
        },
        {
          id: 11,
          show: false,
          title: 'What are the age and height requirements for flying?',
          value: 'You can never be too old to enjoy the gift of flight!  However, babies and toddlers under age 6 are not permitted to fly. Children ages 6-18 are encouraged to fly, they must however, be accompanied by a parent or guardian. Passengers must be 42” or taller. Due to safety concerns, we cannot allow parents to lift their kids up during our flight to see out of the basket. Passenger weight cannot exceed 280 lbs /127 kg per passenger.'
        },
        {
          id: 12,
          show: false,
          title: 'What is your policy on medical conditions and/or disabilities?',
          value: 'We would love to share the gift of flight with everyone we meet. There are a few requirements to be able to do so. Ballooning is relatively low impact, but passengers need to be able to walk one city block at a leisurely pace and be able to withstand a possible jar or jolt of landing equal to jumping down the second step of a staircase.You will need to sign a waiver saying you are of sound physical and mental health and are able to stand for an hour at a time and have no medical diagnosis that could prevent you from flying (if you are unsure what medical conditions would prevent you from flying, please contact your doctor before making a booking).  Any passengers with weak hips, knees, ankles or currently under the care of a physician please advise us of your needs, afterall, safety is our top priority. Unfortunately we cannot take expectant mothers. Pregnant women are not permitted to fly. Please feel free to call us prior to booking with any concerns you may have.'
        },
        {
          id: 13,
          show: false,
          title: 'How can I reserve a flight?',
          value: 'Contact Moab Balloon Flights at <a href="tel:855-6622-759" class="tel">855-662-2759</a> or use our online reservation system and you will soon be sharing the skies with us.'
        },
        {
          id: 14,
          show: false,
          title: 'What is our no show and cancellation policy?',
          value: 'We require 72 hrs notice to cancel a flight. A no show the day of the flight or a cancellation within 72 hrs of the flight will be charged the full rate for the flight. We are not able to refund  the cost of the flight due to a no show or last minute cancellation, sorry, no exceptions. If the flight is cancelled due to weather conditions or safety conditions by Moab Balloon Flights, you WILL NOT be charged for the flight and will be issued a full refund for any amount paid.'
        },
        {
          id: 15,
          show: false,
          title: 'Can I buy a gift certificate?',
          value: 'YES! ABSOLUTELY! Please call us at <a href="tel:855-MOABSKY" class="tel">855-Moab-Sky</a> to arrange. \n' +
            '(Gift Certificates cannot currently be purchased on our website.)\n'
        },
        {
          id: 16,
          show: false,
          title: 'Should we tip our balloon pilot and chase crew?',
          value: 'Being the service provider that we are, a gratuity is always appreciated. A typical tip would be 10% per passenger.'
        },
        {
          id: 17,
          show: false,
          title: 'Celebrating a birthday or an anniversary?',
          value: 'Are you celebrating a special occasion with a loved one? Let us know so we can help make it special! We are happy to put a Happy Birthday or Happy Anniversary banner on our basket to commemorate and celebrate your special flight with us (at no additional charge). Have a cake you would like to bring or have us arrange to have one made? Contact us! Our aim is to make your experience with us a truly special and memorable one.'
        }
      ]
    }
  },
  methods: {
    toggleItem(item, index) {
      this.faqs[index].show = !item.show;
    }
  }
}
</script>


<style lang="scss">
  .faq {
    padding: 60px;
    background: $secondary-color;

    &__subtitle {
      font-weight: bold;
      font-size: 22px;
      padding: 15px;
      color: #E85026;
    }

    &__highlight {
      padding: 50px;
      b {
        color: #fff;
        font-weight: bold;
      }
      p {
        color: #fff;
      }
    }

    &__wrapper {
      @include container();
      margin-top: 50px;
      margin-bottom: 50px;
      display: flex;
      justify-content: center;
      align-items: center;

      .faq-accordion {
        width: 825px;
      }
      .faq-item {
        border-bottom: 1px solid rgba(231, 222, 205, 0.21);

        .accordion {
          background: none;
          border: none;
          color: #fff;
          font-size: 22px;
          cursor: pointer;
          padding: 25px 0px;
          width: 100%;
          text-align: left;
          outline: none;

          &:after {
            content: '';
            float: right;
            border: solid #E85026;
            border-width: 0 3px 3px 0;
            display: inline-block;
            padding: 5px;
            margin-right: 24px;
            background: none;
            height: 0;
            width: 0;
            transition: all 0.3s;
            transform: rotate(45deg);
            -webkit-transform: rotate(45deg);
          }

          &.active:after {
            -webkit-transform: rotate(225deg);
            margin-left: 7px;
          }
        }

        .panel {
          overflow: hidden;

          p {
            text-align: left;
            color: rgba(255, 255, 255, 0.7);

            a {
              color: white;
            }
          }
        }
      }
    }
  }

  @include breakpoint(phone-screen) {
    .faq {
      padding: 50px 15px;

      &__subtitle {
        font-size: 20px;
      }

      &__wrapper {
        .faq-item {
          .accordion{
            font-size: 16px;
            padding-right: 20px;
            &:after {
              margin-right: -20px;
            }
          }
        }
      }
    }
  }
</style>
