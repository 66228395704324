<template>
  <div
    id="contact"
    class="contact"
  >
    <div class="contact__info">
      <h2 class="contact__info-subtitle">
        Unforgetable Moab Adventures
      </h2>
      <h1 class="contact__info-title">
        Contact Us
      </h1>

      <div class="contact__info-group">
        <p class="contact__info-group-title">
          Call or Text
        </p>
        <a
          href="tel:855-662-2759"
          class="contact__info-group-value"
        >
          855.662.2759
        </a>
      </div>

      <div class="contact__info-group">
        <p class="contact__info-group-title">
          Email:
        </p>
        <a
          href="mailto:fly@moabballoonflights.com"
          class="contact__info-group-value"
        >
          fly@moabballoonflights.com
        </a>
      </div>
      <div class="contact__social-media">
        <a :href="constants.FACEBOOK_URL">
          <i class="fa fa-facebook" />
        </a>
        <!--        <a href="#">-->
        <!--          <i class="fa fa-twitter" />-->
        <!--        </a>-->
        <!--        <a href="#">-->
        <!--          <i class="fa fa-linkedin" />-->
        <!--        </a>-->
        <a :href="constants.INSTAGRAM_URL">
          <i class="fa fa-instagram" />
        </a>
      </div>
    </div>
    <div class="contact__form">
      <div
        v-if="show_alert"
        class="alert"
      >
        <p>
          Your message has been sent. We'll be in touch soon.
          <span
            class="close-btn"
            @click="closePopup"
          >
            <i class="fa fa-times" />
          </span>
        </p>
      </div>

      <div
        v-if="show_warning_alert"
        class="alert alert-warning"
      >
        <p>
          There was an error. Please try again
          <span
            class="close-btn"
            @click="show_warning_alert = false"
          >
            <i class="fa fa-times" />
          </span>
        </p>
      </div>

      <form
        method="post"
        @submit.prevent="submit"
      >
        <div
          class="form-group form-group-line"
          :class="{ 'form-group--error': $v.user.name.$error }"
        >
          <div>
            <input
              v-model="user.name"
              type="text"
              placeholder="Name *"
              :class="submitted && !$v.user.name.required ? 'invalid' : ''"
            >
            <small
              v-if="submitted && !$v.user.name.required"
              class="form-invalid"
            >
              Name is required
            </small>
          </div>
          <div>
            <input
              v-model="user.email"
              type="email"
              placeholder="Email *"
              :class="submitted && (!$v.user.email.required || !$v.user.email.email) ? 'invalid' : ''"
            >
            <small
              v-if="submitted && !$v.user.email.required"
              class="form-invalid"
            >
              Email is required
            </small>
            <small
              v-if="submitted && !$v.user.email.email"
              class="form-invalid"
            >
              Email is invalid
            </small>
          </div>
        </div>
        <div class="form-group">
          <textarea
            v-model="user.message"
            placeholder="Message *"
            :class="submitted && !$v.user.message.required ? 'invalid' : ''"
          />
          <small
            v-if="submitted && !$v.user.message.required"
            class="form-invalid"
          >
            Message is required
          </small>
        </div>
        <div class="form-group recaptcha">
          <vue-recaptcha
            ref="recaptcha"
            :sitekey="siteKey"
            size="invisible"
            @verify="onCaptchaVerified"
            @expired="onCaptchaExpired"
          />
        </div>
        <div class="form-group">
          <button
            class="btn btn-primary"
            type="submit"
            :disabled="btn_disabled == 1"
          >
            {{ text_send_message }}
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import Vuelidate from 'vuelidate'
import VueRecaptcha from 'vue-recaptcha'
import * as constants from '@/constants'

import axios from 'axios'
import { required, email } from 'vuelidate/lib/validators'

Vue.use(Vuelidate)

export default {
  name: 'Contact',
  components: { VueRecaptcha },
  data() {
    return {
      user: {
        name: '',
        email: '',
        message: '',
      },
      submitted: false,
      siteKey: process.env.VUE_APP_RECAPTCHA_SITE_KEY,
      btn_disabled: 0,
      text_send_message: 'Send Message',
      show_alert: false,
      show_warning_alert: false,
      constants: constants
    }
  },
  validations: {
    user: {
      name: { required },
      email: { required, email },
      message: { required }
    }
  },

  methods: {
    submit: function () {

      this.show_warning_alert = false;

      if (this.submitted) {
        this.$v.$touch();
        if(this.$v.$invalid) return
        this.btn_disabled = 1;
        this.text_send_message = 'Sending...';

        this.sendEmail();
      } else {
        this.submitted = true;

        this.$v.$touch();
        if(this.$v.$invalid) return

        this.btn_disabled = 1;
        this.text_send_message = 'Sending...';
        this.$refs.recaptcha.execute();
      }

    },
    onCaptchaVerified: function (recaptchaToken) {
      this.sendEmail(recaptchaToken);
    },
    onCaptchaExpired: function () {
      this.$refs.recaptcha.reset();
    },
    sendEmail: function(recaptchaToken){
      var self = this;
      var data = {
        name: this.user.name,
        email: this.user.email,
        message: this.user.message,
      }

      if (recaptchaToken) {
        data.recaptchaToken= recaptchaToken;
      }

      axios.post(process.env.VUE_APP_API_URL+'/send-email', data,{
        timeout: 10000
      })
        .then(function (response) {
          self.btn_disabled = 0;
          self.text_send_message = 'Send Message';

          setTimeout(function() {
            var form = document.querySelector('form');
            form.reset();
          }, 0);

          self.show_alert = true;
        })
        .catch(function (error) {
          self.show_warning_alert = true;
          self.btn_disabled = 0;
          self.text_send_message = 'Send Message';
        });
    },
    closePopup: function(){
      this.show_alert = false

      setTimeout(function(){
        var form = document.querySelector('form');
        form.reset();
      }, 0);
    }
  }
}
</script>

<style lang="scss" scoped>
  .contact {
    height: 664px;
    background: url('../assets/images/Rectangle2.png') no-repeat;
    background-size: cover;
    background-position: center;
    display: grid;
    grid-template-columns: 1fr 1fr;

    h1, h2 {
      @include breakpoint(phone-screen) {
        text-align: center;
      }
    }

    @include breakpoint(phone-screen) {
      height: inherit;
      grid-template-columns: 1fr;
    }

    &__info {
      @include breakpoint(phone-screen) {
        text-align: left;
        padding-top: 30px;
        padding-left: 30px;
      }

      @include breakpoint(tablet-screen) {
        text-align: left;
        padding-top: 150px;
        padding-left: 60px;
      }

      @include breakpoint(desktop-screen) {
        text-align: left;
        padding-top: 150px;
        padding-left: 100px;
      }

      @include breakpoint(large-desktop-screen) {
        text-align: left;
        padding-top: 150px;
        padding-left: 180px;
      }

      .contact__info-subtitle{
        color: $primary-color;
        font-size: 19px;
        font-weight: normal;
        margin-top: 15px;
        margin-bottom: 15px;
        padding: 0;
      }
      .contact__info-title{
        color: #fff;
        font-size: 44px;
      }

      .contact__info-group{
        margin-bottom: 30px;

        p{
          font-size: 18px;
          line-height: 1.6em;
          margin: 0;

          &.contact__info-group-title{
            color: $primary-color;
          }
        }

        a.contact__info-group-value{
          font-size: 18px;
          line-height: 1.6em;
          margin: 0;
          color: #fff;
          text-decoration: none;

          &:hover {
            text-decoration: underline;
          }
        }
      }
    }

    &__social-media{
      margin-top: 70px;

      @include breakpoint(phone-screen) {
        margin-top: 40px;
      }
      a {
        color: #fff;
        padding-right: 24px;
        font-size: 20px;
      }
    }

    &__form{

      @include breakpoint(phone-screen) {
        padding: 0;
        margin-top: 20px;
        margin-bottom: 30px;

        form {
          margin: 30px;
          width: calc(100% - 60px);
        }
      }

      @include breakpoint(tablet-screen) {
        text-align: left;
        padding: 180px 60px 150px 0px;
      }

      @include breakpoint(desktop-screen) {
        text-align: left;
        padding: 180px 100px 150px 0px;
      }

      @include breakpoint(large-desktop-screen) {
        text-align: left;
        padding: 180px 150px 150px 0px;
      }

      .alert{
        background: #fff;
        margin-top: -80px;
        padding: 5px 25px;
        border-radius: 5px;
        font-size: 14px;
        margin-bottom: 20px;
        text-align: left;

        &.alert-warning {
          background: #d93025;
          color: #fff;
        }

        @include breakpoint(phone-screen) {
          margin: 30px;
        }

        span.close-btn {
          float: right;
          margin-top: -10px;
          margin-right: -10px;
          cursor: pointer;
        }
      }

      .form-group{
        margin-bottom: 30px;

        &.form-group-line{
          display: grid;
          grid-template-columns: 1fr 1fr;
          grid-column-gap: 30px;

          @include breakpoint(phone-screen) {
            grid-template-columns: 1fr;
            grid-row-gap: 30px;
          }

          input {
            width: calc(100% - 30px);
          }
        }

        input, textarea {
          color: #e7decd;
          outline: none;
          background: none;
          border-radius: 4px;
          border: 1px solid rgba(231, 222, 205, .37);
          padding: 15px;
          transition: all 0.3s;

          &:focus {
            border: 1px solid rgba(231, 222, 205, .8);
          }

          &.invalid {
            border-color: $primary-color;
          }

        }

        textarea{
          width: calc(100% - 30px);
          height: 155px;
        }

        button{
          float: right;

          @include breakpoint(phone-screen) {
            float: none;
            width: 100%;
          }
        }

        .form-invalid{
          color: $primary-color;
        }

      }
    }

    .btn {
      outline: none;
      padding: 13px;
      font-size: 18px;
      width: 300px;

      &:disabled {
        background: #f79279;
      }
    }
  }

</style>
<style>
  .grecaptcha-badge {
    display: none !important;
  }
</style>
